const append = (selector, content) => {
  $(selector).append(content);
};

const html = (selector, content) => {
  $(selector).html(content);
};

const modal = (selector, content) => {
  const $item = $(selector);
  $('body').append(content);
  $(selector).last().remodal({ hashTracking: false }).open();
  if ($item.length === 0) return;
  $item.hide();
  setTimeout(() => { $item.remove(); }, 50);
};

const replace = (selector, content) => {
  const $item = $(selector);
  $(content).insertAfter($item);
  $item.hide();
  setTimeout(() => { $item.remove(); }, 50);
};

export default { append, html, modal, replace };
