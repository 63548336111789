import 'rails-plus/es6/libs';
import 'modules/pagy.js.erb';

import {
  BlueimpGallery,
  FlashItems,
  Flips,
  PageEvent,
  Remodals,
  SmoothScrollLinks,
  WebFontLoader,
} from 'rails-plus';

import $                          from 'jquery';
import Rails                      from 'rails-ujs';
import Turbolinks                 from 'turbolinks';
import { Application }            from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import FontAwesome         from 'modules/FontAwesome';
import MainSlider          from 'modules/MainSlider';
import ujs                 from 'modules/ujs';

window.$ = $;
window.jQuery = $;
window.ujs = ujs;

BlueimpGallery.start();
FlashItems.start();
Flips.start();
MainSlider.start();
Rails.start();
Remodals.start();
SmoothScrollLinks.start();
Turbolinks.start();

PageEvent.delegate('turbolinks:load');
WebFontLoader.load('Roboto', 'roboto-loaded');

const application = Application.start();
const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
