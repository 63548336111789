import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['card', 'link', 'links']

  connect() {
    this.linksTarget.classList.remove('d_n');
  }

  show(event) {
    const visibleNodes = this.cardTargets.filter(node =>
      node.getAttribute('data-category') == event.target.textContent
    );

    if (!visibleNodes) return;

    this.linkTargets.forEach(node => node.classList.remove('link_active'));
    event.target.classList.add('link_active');
    this.cardTargets.forEach(node => node.classList.add('d_n'));
    visibleNodes.forEach(node => node.classList.remove('d_n'));
  }

  showAll() {
    this.linkTargets.forEach(node => node.classList.remove('link_active'));
    this.cardTargets.forEach(node => node.classList.remove('d_n'));
  }
}
