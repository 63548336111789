import Flickity from 'flickity';

const destroyNode = (node) => {
  Flickity.data(node).destroy();
};

const destroyAll = () => {
  const nodes = document.querySelectorAll('.slider-wrapper.flickity-enabled');
  [...nodes].forEach(node => destroyNode(node));
};

const initNode = (node) => {
  new Flickity(node, {
    autoPlay: 5000,
    cellSelector: '.slider-item',
    wrapAround: true,
  });
};

const init = () => {
  const nodes = document.querySelectorAll('.slider-wrapper');
  [...nodes].forEach(node => initNode(node));
};

const start = () => {
  document.addEventListener('turbolinks:before-cache', destroyAll);
  document.addEventListener('page:load', init);
};

export default { start };
